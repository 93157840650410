import React from "react";
import HeaderView from "./components/header";
import { Spin } from "antd";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const ImageMovieMap = Loader.loadBusinessComponent("ImageMovieMap");
const Wrapper = Loader.loadBusinessComponent("BaseLibDetails", "Wrapper");
const Loading = Loader.Loading;
const PageDetails = Loader.loadBusinessComponent("PageDetails");
const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");
const AccessControl = Loader.loadBusinessComponent("Card", "AccessControl");
const HorizontalScrollLayout = Loader.loadBaseComponent(
  "HorizontalScrollLayout"
);

export default class faceLibraryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      //默认选中
      activeId: "",
      //当前数据
      nowItem: {},
      //搜索条件
      searchData: {},
      //人脸还是人体
      type: "face",
      // 列表
      sourceData: [],
      //车辆基本数据
      baseData: {}
    };
  }
  componentWillMount() {
    const { location } = this.props;
    const { id } = Utils.queryFormat(location.search);
    window.LM_DB.get("parameter", id).then(data => {
      if (!data) {
        return;
      }
      this.setState({
        activeId: data.id,
        nowItem: data.data,
        sourceData: data.list,
        searchData: data.searchData,
        type: data.type,
        baseData: data.vehicleData ? data.vehicleData : {},
        loading: false
      });
    });
  }
  /**
   * @desc 点击切换
   */
  changeActiveId = activeId => {
    let { sourceData } = this.state;
    this.setState({
      activeId,
      nowItem: sourceData.filter(v => v.id === activeId)[0]
    });
  };

  renderContent() {
    let {
      loading,
      activeId,
      nowItem,
      searchData,
      type,
      sourceData,
      baseData
    } = this.state;
    if (loading) {
      return <Loading />;
    }
    //所有列表数据
    let index = sourceData.findIndex(v => v.id === activeId);
    //上一条数据
    let preData = sourceData[index - 1];
    //下一条第一条数据
    let nextData = sourceData[index + 1];
    if(type === 'accessControl'){
      if(preData){
        preData.accessControlUrl = preData.pictureUrl
      }
      if(nextData){
        nextData.accessControlUrl = nextData.pictureUrl
      }
    }
    nowItem.feature = nowItem.faceRect ? nowItem.faceFeature : nowItem.bodyFeature;
    return (
      <React.Fragment>
        <HeaderView {...nowItem} type={type} baseData={baseData} />
        <div className="picture-container">
          {preData && (
            <div className="nav-l">
              <PageDetails
                imgUrl={preData[`${type}Url`]}
                onChange={this.changeActiveId}
                id={preData.id}
                pageType={'pre'}
              />
            </div>
          )}
          {nextData && (
            <div className="nav-r">
              <PageDetails
                imgUrl={nextData[`${type}Url`]}
                id={nextData.id}
                onChange={this.changeActiveId}
                pageType={'next'}
              />
            </div>
          )}
          <ImageMovieMap
            type={type}
            video={type === 'accessControl' ? true : false}
            data={nowItem}
            key={nowItem.id}
            searchData={searchData}
          />
        </div>
        <HorizontalScrollLayout
          size={6}
          data={sourceData}
          currentIndex={index}
          renderItem={v => type !== 'accessControl' ? (
            <CaptureCard
              className="detail-box-item"
              relativeIcon=""
              hoverScale={false}
              deviceName={v.deviceName}
              active={activeId === v.id ? true : false}
              captureTime={v.captureTime}
              onClick={() => this.changeActiveId(v.id)}
              imgUrl={v[`${type}Url`]}
            >
              {type === 'vehicle' && nowItem.accessType && (
                <IconFont
                  style={{ color: "var(--icon)" }}
                  type={
                    nowItem.accessType === "117052"
                      ? "icon-S_Allow_CarIn"
                      : "icon-S_Allow_AllocationIn"
                  }
                />
              )}
            </CaptureCard>
          ) : (<AccessControl 
                item={v}
                className='access-control-detail-card'
                hoverScale={false}
                active={activeId === v.id ? true : false}
                onClick={() => this.changeActiveId(v.id)}
          />)}
        />
      </React.Fragment>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <Wrapper>
        <Spin spinning={loading}>
          <div
            className="obj-snapshot-detail"
            style={{ width: "100%", height: "100%", minHeight: 400 }}
          >
            {this.renderContent()}
          </div>
        </Spin>
      </Wrapper>
    );
  }
}
