import React from "react";

const LabelValue = Loader.loadBaseComponent("LabelValue");
const ImageView = Loader.loadBaseComponent("ImageView");

export default ({
  openTime,
  captureTime,
  deviceName,
  vehiclePlateNum,
  baseData,
  accessType,
  cardNo,
  cardOwner,
  address,
  type
}) => {
  const getItem = (label, value) => {
    return (
      <LabelValue className="item" label={label} value={value || "暂无"} />
    );
  };
  let accessTypes = "其他";
  if (type === "accessControl") {
    let accessTypeItem = Dict.map.openType.find(v => v.value === accessType);
    if (accessTypeItem) {
      accessTypes = accessTypeItem.label;
    }
  }
  return type !== "accessControl" ? (
    <div className="box-container">
      {type !== "vehicle" && (
        <div className="header">
          {getItem("抓拍地点", deviceName)}
          {getItem(
            "抓拍时间",
            Utils.formatTimeStamp(parseInt(captureTime, 10))
          )}
        </div>
      )}
      {type === "vehicle" && (
        <>
          <div className="header">
            {getItem(
              "车牌颜色",
              Array.isArray(baseData.vehiclePlateColour)
                ? baseData.vehiclePlateColour
                    .map(v => Dict.getLabel("plateColor", v, true))
                    .join(",")
                : Dict.getLabel("plateColor", baseData.vehiclePlateColour, true)
            )}
            {getItem(
              "车辆颜色",
              Array.isArray(baseData.vehicleColor)
                ? baseData.vehicleColor
                    .map(v => Dict.getLabel("vehicleColor", v, true))
                    .join(",")
                : Dict.getLabel(
                    "vehicleColor",
                    baseData.vehicleColor,
                    true
                  )
            )}
            {getItem("车牌号码", vehiclePlateNum)}
          </div>
          <div className="header">
            {getItem(
              "通行时间",
              Utils.formatTimeStamp(parseInt(captureTime, 10))
            )}
            {getItem("通过设备", deviceName)}
            {getItem(
              "车辆品牌",
              Array.isArray(baseData.vehicleBrand)
                ? baseData.vehicleBrand
                    .map(v => Dict.getLabel("vehicleBrands", v))
                    .join(",")
                : Dict.getLabel("vehicleBrands", baseData.vehicleBrand)
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="access-records-detail">
      <div className="pic-box">
        <div className="img-box">
          <ImageView src={baseData.portraitPictureUrl} />
          <div className="pic-info">登记照片</div>
        </div>
      </div>
      <div className="info-box">
        {getItem("姓名", baseData.personName || cardOwner)}
        {getItem("开门类型", accessTypes)}
        {getItem("门禁卡卡号", cardNo)}
        {getItem("门禁位置", address)}
        {getItem("开门时间", Utils.formatTimeStamp(openTime))}
      </div>
    </div>
  );
};
